import React, { useEffect, useState } from "react";
// import sections
import Hero from "../components/sections/Hero";
import FeaturesTiles from "../components/sections/FeaturesTiles";
import FeaturesSplit from "../components/sections/FeaturesSplit";
import Testimonial from "../components/sections/Testimonial";
import Cta from "../components/sections/Cta";

const HomeEl = () => {
  return (
    <>
      <Hero lang={1} className="illustration-section-01" />
      <FeaturesTiles lang={1} />
      <FeaturesSplit
        lang={1}
        invertMobile
        topDivider
        imageFill
        className="illustration-section-02"
      />
      <Testimonial lang={1} topDivider />
      {/* <Cta split /> */}
    </>
  );
};

export default HomeEl;
