import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Image from "../../elements/Image";

const FooterNav = ({ className, lang, ...props }) => {
  const classes = classNames("footer-nav", className);

  return (
    
    <nav {...props} className={classes}>
 
      <ul className="list-reset">
      
        {/* {lang ? (
          <li>
            <Link to="#0">Επικοινωνία</Link>
          </li>
        ) : (
          <li>
            <Link to="#0">Contact</Link>
          </li>
        )}
        {lang ? (
          <li>
            <Link to="#0">Σχετικά με εμάς</Link>
          </li>
        ) : (
          <li>
            <Link to="#0">About us</Link>
          </li>
        )} */}

        {/* <li>
          <Link to="#0">About us</Link>
        </li>
        <li>
          <Link to="#0">FAQ's</Link>
        </li>
        <li>
          <Link to="#0">Support</Link>
        </li> */}
      </ul>
    </nav>
  );
};

export default FooterNav;
