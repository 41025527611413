import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  lang,
  ...props
}) => {
  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const sectionHeader = {
    title: "Customer testimonials",
    // paragraph:
    //   "Vitae aliquet nec ullamcorper sit amet risus nullam eget felis semper quis lectus nulla at volutpat diam ut venenatis tellus—in ornare.",
  };

  const sectionHeaderEl = {
    title: "Γνώμη από χρήστες των εφαρμογών ",
    // paragraph:
    //   "Vitae aliquet nec ullamcorper sit amet risus nullam eget felis semper quis lectus nulla at volutpat diam ut venenatis tellus—in ornare.",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          {/* {lang ? (
            <SectionHeader data={sectionHeaderEl} className="center-content" />
          ) : (
            <SectionHeader data={sectionHeader} className="center-content" />
          )} */}

          <div className={tilesClasses}>
            {/* <div
              className="tiles-item reveal-from-right"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  {lang ? (
                    <p className="text-sm mb-0">
                      — Πολύ καλό application με τεχνικά χαρακτηριστικά που σου
                      λύνουν τα χέρια. Λειτουργεί για εμένα ως επαγγελματικός
                      οδηγός.
                    </p>
                  ) : (
                    <p className="text-sm mb-0">
                      — Very good app with technical features that help with the
                      promotion of the products.
                    </p>
                  )}
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  {lang ? (
                    <span className="testimonial-item-name text-color-high">
                      Δημήτριος Α.
                    </span>
                  ) : (
                    <span className="testimonial-item-name text-color-high">
                      Dimitrios A.
                    </span>
                  )}

                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    {lang ? (
                      <a href="https://koufomata-stock.gr" target="_blank">
                        εξειδικευμένη εμπορική εφαρμογή
                      </a>
                    ) : (
                      <a href="https://koufomata-stock.gr" target="_blank">
                        specialized commercial related app
                      </a>
                    )}
                  </span>
                </div>
              </div>
            </div> */}

            {/* <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  {lang ? (
                    <p className="text-sm mb-0">
                      — Έχω μαζεμένες τις εργασίες του κάθε μήνα για το χωράφι
                      και με χρήση φωτογραφιών. Οργανώνει αυτά που αλλιώς κάνεις
                      εμπειρικά.
                    </p>
                  ) : (
                    <p className="text-sm mb-0">
                      — It helps me organize the rural tasks of every month. It
                      puts all tasks in one place.
                    </p>
                  )}
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  {lang ? (
                    <span className="testimonial-item-name text-color-high">
                      Ανδρονίκη Μ.
                    </span>
                  ) : (
                    <span className="testimonial-item-name text-color-high">
                      Androniki M..
                    </span>
                  )}
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    {lang ? (
                      <a href="#0">εξειδικευμένη αγροτική web εφαρμογή</a>
                    ) : (
                      <a href="#0">specialized agriculture related web app</a>
                    )}
                  </span>
                </div>
              </div>
            </div> */}


            
            {/* 
            <div
              className="tiles-item reveal-from-left"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    — Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum cillum dolore eu fugiat.
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">
                    Ben Stafford
                  </span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a href="#0">AppName</a>
                  </span>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;
