import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  lang,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "Products",
    paragraph: "The products we stock are handpicked and first meet our own high manufacturing quality criteria. Some of them are:",
  };

  const sectionHeaderEl = {
    title:
      "Προϊόντα",
    paragraph: "Τα προϊόντα που διαθέτουμε είναι επιλεγμένα και ικανοποιούν πρώτα τα δικά μας υψηλά κριτήρια ποιότητας κατασκευής. Μερικά από αυτά είναι:",
  };


  const sectionClientsHeader = {
    title: "Clients",
    paragraph: "In all the years of our activity, our clients are :",
  };

  const sectionClientsHeaderEl = {
    title:
      "Πελάτες",
    paragraph: "Σε όλα τα χρόνια δραστηριοποίησής μας, οι πελάτες μας είναι :",
  };




  const sectionContactHeader = {
    title: "Contact us",
    paragraph: "",
  };

  const sectionContactHeaderEl = {
    title:
      "Επικοινωνία",
    paragraph: "",
  };


  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          {lang ? (
            <><SectionHeader data={sectionHeaderEl} className="center-content" />
            <li className="center-content">Συνθετικά Κουφώματα (Rehau)</li>
            <li className="center-content">Θωρακισμένες & Εσωτερικές Πόρτες</li>
            <li className="center-content">Ρολλά για Γκαράζ και Προθήκες</li>
            <li className="center-content">Γκαραζόπορτες (Hormann, Normstahl)</li>
            <li className="center-content">Μηχανισμοί Αυτοματοποίησης Θυρών (FAAC, Nice)</li>
            <li className="center-content">Υδραυλικοί Σωλήνες (Aquatherm, Rehau)</li>
            <li className="center-content"> Είδη Θέρμανσης</li>
            <h2 className="center-content">Δείτε την ΝΕΑ online εφαρμογή μας με stock αποθέματα </h2>
            <h3 className="testimonial-item-link center-content">
                      <a href="https://koufomata-stock.gr" target="_blank">
                        Είσοδος koufomata-stock.gr
                      </a>
                  </h3>     
           </>
          ) : (
            <><SectionHeader data={sectionHeader} className="center-content" />
            <li className="center-content">Synthetic Frames (Rehau)</li>
             <li className="center-content">Armoured & Interior Doors</li>
             <li className="center-content">Rolls for Garages and Showcases</li>
             <li className="center-content">Garage doors (Hormann, Normstahl)</li>
             <li className="center-content">Door Automation Mechanisms (FAAC, Nice)</li>
             <li className="center-content">Hydraulic Hoses (Aquatherm, Rehau)</li>
             <li className="center-content"> Heating Systems</li>
             <h2 className="center-content">Visit our NEW web app with stock products </h2>
            <h3 className="testimonial-item-link center-content">
                      <a href="https://koufomata-stock.gr" target="_blank">
                        Enter koufomata-stock.gr
                      </a>
                  </h3>  
                  </>
          )}



          <div className="container">
        <div className={innerClasses}>
          {lang ? (
            <><SectionHeader data={sectionClientsHeaderEl} className="center-content" />
            <li className="center-content">Πάνω από 3000 νοικοκυριά</li>
            <li className="center-content">Δημόσιες Υπηρεσίες</li>
            <li className="center-content">Επαγγελματίες</li>
            <li className="center-content"> Βιοτεχνίες, Βιομηχανίες</li></>
          ) : (
            <><SectionHeader data={sectionClientsHeader} className="center-content" />
            <li className="center-content">Over 3000 households</li>
             <li className="center-content">Public Services</li>
             <li className="center-content">Professionals</li>
             <li className="center-content"> Industries</li></>
          )}
          </div>
          </div>





          <div className="container">
        <div className={innerClasses}>
          {lang ? (
            <><SectionHeader data={sectionContactHeaderEl} className="center-content" />
            <p className="center-content">Διεύθυνση : Αναλήψεως 83 ΒΟΛΟΣ</p> 
            <p className="center-content"> τηλ.: +30 2421044800</p>
            <p className="center-content"> email.: salesATaivalis.gr</p>
            </>
            
          ) : (
            <><SectionHeader data={sectionContactHeader} className="center-content" />
            <p className="center-content">Address : Analipseos str. 83, Volos, Greece</p> 
            <p className="center-content"> tel.: +30 2421044800</p>
            <p className="center-content"> email.: salesATaivalis.gr</p>
            
            
            </>
           
          )}
          {/* <Image        
                  src={require("./../../assets/images/aivaLogo.jpeg")}
                  width={400}
                  height={250}
                  className="circle-image"
                /> */}
               
          </div>
          
          </div>

         

          {/* <div className={splitClasses}>


          <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  web/industry
                </div>
                {lang ? (
                  <h3 className="mt-0 mb-12">
                    σύνδεση αισθητήρα θερμοκρασίας/υγρασίας με το cloud και με τη τοποθεσία του μέσω GPS
                  </h3>
                ) : (
                  <h3 className="mt-0 mb-12">
                    temperature/humidity sensor connection with the cloud and its position using GPS
                  </h3>
                )}

                {lang ? (
                  <p className="m-0">
                    Χρησιμοποιώντας εναν αισθητήρα DHT 11/22 και μια ESP32 μίνι πλακέτα, γίνεται αποστολή των δεδομένων του αισθητήρα στο cloud.
                    Τα δεδομένα καθώς και η τοποθεσία του αισθητήρα εμφανίζονται σε web εφαρμογη πάνω σε χάρτη της περιοχής. Ο χρήστης έχει τη δυνατότητα
                    να προσθέσει νέα σημεία στο χάρτη και δυνητικά να τοποθετηθούν εκεί νέοι αισθητήρες.
        
                    
                  </p>
                ) : (
                  <p className="m-0">
                    Using a DHT 11/22 sensor and an ESP32 mini board, the sensor data are being sent to the cloud.
                    The sensor data and its position are being displayed on a web app along and on a local area map. The user can add new
                    map pins and potentially add new sensors.

                  </p>
                )}
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/plaketa.png")}
                  alt="Features split 04"
                  width={528}
                  height={396}
                />
                <br></br>
                 <Image
                  src={require("./../../assets/images/web_app_sensorM.png")}
                  alt="Features split 04"
                  width={528}
                  height={396}
                />
              </div>
            </div>


            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  web-android-ios-windows
                </div>
                {lang ? (
                  <h3 className="mt-0 mb-12">
                    web εφαρμογή - ακριβές σημείο παρκαρίσματος (σύνδεση με
                    Google Maps API)
                  </h3>
                ) : (
                  <h3 className="mt-0 mb-12">
                    web app - where did i park (connection with Google Maps API)
                  </h3>
                )}

                {lang ? (
                  <p className="m-0">
                    H εφαρμογή αποθηκεύει στη βάση τις συντεταγμένες του οδηγού
                    του οχήματος, τη στιγμή που παρκάρει το όχημα, εφόσον
                    εκείνος το επιλέξει. Έπειτα, ανά πάσα στιγμή, μπορεί
                    οποιοσδήποτε με πρόσβαση στην εφαρμογή να δει στο χάρτη πού
                    είναι το όχημα. Χρησιμοποιείται από 3 εταιρίες στην Ελλάδα.
                  </p>
                ) : (
                  <p className="m-0">
                    This app tracks the exact gps location of the driver who
                    just parked a vehicle. Anyone with access to the app, can
                    see afterwards where the vehicle is parked on Google Maps.
                    Useful for companies witth multiple vehicles and drivers.
                    Already used by 3 companies in Greece.
                  </p>
                )}
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/features-split-image-05.png")}
                  alt="Features split 05"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  android
                </div>
                {lang ? (
                  <h3 className="mt-0 mb-12">mobile λογιστική εφαρμογή</h3>
                ) : (
                  <h3 className="mt-0 mb-12">mobile app for accountants</h3>
                )}

                {lang ? (
                  <p className="m-0">
                    Επικοινωνία μέσω SOAP API με τη βάση δεδομένων της ΑΑΔΕ. Με
                    τη χρήση αυτής της εφαρμογής, εταιρίες μπορούν να
                    αναζητήσουν βασικές πληροφορίες, προκειμένου να διακριβώσουν
                    τη φορολογική ή την επαγγελματική υπόσταση ΑΛΛΩΝ
                    φορολογουμένων/φυσικών προσώπων που ασκούν επιχειρηματική
                    δραστηριότητα.
                  </p>
                ) : (
                  <p className="m-0">
                    Communicating via a SOAP API with the database of the greek
                    finance institute (AADE) it helps greek companies to find
                    financial and accounting info about other companies.
                  </p>
                )}
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/features-split-image-01.png")}
                  alt="Features split 02"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  web
                </div>
                {lang ? (
                  <h3 className="mt-0 mb-12">
                    web εφαρμογή για αγροτικές εργασίες
                  </h3>
                ) : (
                  <h3 className="mt-0 mb-12">
                    web app for agriculture planning
                  </h3>
                )}

                {lang ? (
                  <p className="m-0">
                    Η εφαρμογή βοηθά ενεργά στον σχεδιασμό και προγραμματισμό
                    διάφορων αγροτικών εργασιών. Χρησιμοποιείται στη Κεντρική
                    Ελλάδα.
                  </p>
                ) : (
                  <p className="m-0">
                    This app actively helps farmers to program and handle their
                    agriculture activities. Used in central Greece.
                  </p>
                )}
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/features-split-image-02.png")}
                  alt="Features split 02"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  web
                </div>
                {lang ? (
                  <h3 className="mt-0 mb-12">
                    web λειτουργία "Προσθήκη στο Καλάθι"
                  </h3>
                ) : (
                  <h3 className="mt-0 mb-12">
                    Add to Cart - full functionality
                  </h3>
                )}
                {lang ? (
                  <p className="m-0">
                    Η λειτουργία ενσωματώθηκε σε ήδη υπάρχον σαιτ ώστε να
                    υποστηρίξει "Καλάθι Πελάτη". Γίνεται καταγραφή και
                    διαχείριση των προϊόντων, των τιμών, των ποσοτήτων αλλά και
                    των παραγγελιών.
                  </p>
                ) : (
                  <p className="m-0">
                    Built in raw PHP (no framework used) since the client wanted
                    the raw code, this web functionality offered a full Add to
                    Cart experience with saved cart items, prices, quantity etc
                    and stored orders after the confirmation of the order.
                  </p>
                )}
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/features-split-image-03.png")}
                  alt="Features split 03"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  web
                </div>
                {lang ? (
                  <h3 className="mt-0 mb-12">
                    web εφαρμογή για αγοραπωλησία αγαθών
                  </h3>
                ) : (
                  <h3 className="mt-0 mb-12">
                    web app for selling/advertising products
                  </h3>
                )}

                {lang ? (
                  <p className="m-0">
                    Αυτή η εφαρμογή-κορμός παρέχει απόλυτο έλεγχο σε προς πώληση
                    αγαθά. Δημιουργία, επεξεργασία και εμφάνιση των προϊόντων
                    προς πώληση. Επίσης, παρέχονται οι πληροφορίες του εκάστοτε
                    πωλητή.
                  </p>
                ) : (
                  <p className="m-0">
                    Τhis app provides absolute control on adding, editing and
                    displaying any kind of product for sale. Info for the person
                    who sells the product is also provided. An app that can be
                    used as a skeleton for any kind of business.
                  </p>
                )}
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/features-split-image-04.PNG")}
                  alt="Features split 04"
                  width={528}
                  height={396}
                />
              </div>
            </div>
            
          </div> */}


        </div>
      </div>
    </section>
  );
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
